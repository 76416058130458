<template>
  <div class="home">
    <top></top>
    <div class="homeheader">
      <!-- 首页头部 -->
      <div class="content">
        <div class="left">
        
          <img src="../../assets/index/login.png" alt="" />
        </div>

        <div class="center">
          <el-input
            placeholder="请输入标题..."
            v-model="title"
            @keyup.enter.native="searchpublic"
            clearable
          >
          </el-input>
          <el-button type="danger" @click="searchpublic">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="centerexchange">
      <div class="nav-wrap">
        <div class="apps">
          <div class="reheader">
            <nav v-for="(tabBar, index) in data" :key="index" class="rediv">
              <div
                class="a"
                :class="{ active: intention == tabBar.id }"
                @click="toggleTab(tabBar.id)"
              >
                {{ tabBar.name }}
              </div>
            </nav>
          </div>
          <div>
            <div
              class="commiddlee"
              v-for="(item, index) in informationdata"
              :key="index"
            >
              <div class="itemexchange">
                <img v-if="item.image" :src="item.image" v-viewer alt="" />
                <img
                  v-else
                  class="avatar"
                  height="150"
                  width="150"
                  v-viewer
                  src="../../assets/home/nullimage.png"
                />
                <div class="itemcenter">
                  <div class="relecontent">
                    {{ item.title }}
                  </div>
                  <div>
                    {{ item.username ? item.username : "未设置昵称" }}
                  </div>

                  <div class="relebottomo">
                    {{ item.describe }}
                  </div>
                </div>

                <div class="relediv">
                  <!-- <div>
            <img v-if="startidear" src="../../assets/home/red.png" alt=""  @click="red(item.uuid)" />
          <img v-else src="../../assets/home/hui.png"  @click="hui(item.uuid)" alt="" />
           </div> -->
                  <router-link
                    :to="{ path: '/exchangeDetial', query: { id: item.uuid } }"
                    tag="div"
                    class="contente"
                  >
                    <el-button>查看详情</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    >
    </el-pagination> -->
          <Pagination
            class="el-pagination"
            ref="changepage"
            
            :num="totalCount"
            :limit="pageSize"
            :totalPageCount="totalPageCount"
          ></Pagination>
        </div>
      </div>
    </div>
    <Foo></Foo>
  </div>
</template>

<script>
// import ds from "../../assets/index/item.png"
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import informationApi from "../../api/informationApi";
import loginApi from "../../api/loginApi";
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import Pagination from "@/components/prePage/pagination.vue";
import { Message } from "element-ui";
export default {
  data() {
    return {
      scarch: "",
      title: "",
      // 意向（0求购，1出售）
      intention: 0,
      pageNum: 1,
      pageSize: 4,
      totalCount: 0,
      totalPageCount: 0,
      informationdata: [],

      data: [
        { name: "求购", id: 0 },
        { name: "出售", id: 1 },
      ],
      tabIndex: 0,
      type: 0,
      // 红星
      startidear: false,
    };
  },
  created() {},
  components: {
    // HelloWorld
    Top,
    Foo,
    Pagination,
  },
  mounted() {
    this.searchpublic();
  },
  methods: {
    change(even) {
      this.pageNum = even;
      this.searchpublic();
    },
    toggleTab(index) {
      this.intention = index;
      this.$refs.changepage.pageOffset(1);
      this.change(1);
    },
    //搜索
    searchpublic() {
      informationApi.getInformationExhibitList(
        {
          title: this.title,
          intention: this.intention,
          page: this.pageNum,
          size: this.pageSize,
        },
        (res) => {
          if (res.data.code == 200) {
            this.informationdata = res.data.result.data;
            this.totalCount = res.data.result.totalCount;
            this.totalPageCount = res.data.result.totalPageCount;
            this.getCollectRecordStateInformation(this.informationdata);
          }
        }
      );
    },

    //  获取当前信息收藏状态
    getCollectRecordStateInformation(item) {
      if (item.length > 0) {
        for (let index = 0; index < item.length; index++) {
          const element = item[index].uuid;
          informationApi.getCollectRecordStateInformation(
            { sourceId: element },
            (res) => {
              if (res.data.code == 200) {
                this.startidear = res.data.result;
              }
            }
          );
        }
      }
    },
    // 添加收藏
    hui(id) {
      let isLogin = localStorage.getItem("Login_data");
      if (isLogin) {
        loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
              localStorage.removeItem("Login_data");
            }
          }
        });
        informationApi.addCollectRecordInformation({ sourceId: id }, (res) => {
          if (res.data.code == 200) {
            Message.success("收藏成功");
            this.startidear = true;
          } else {
            Message.error(res.data.message);
          }
        });
      } else {
        Message.error("请先登录");
      }
    },
    // 取消收藏
    red(id) {
      informationApi.delCollectRecordInformation({ sourceId: id }, (res) => {
        if (res.data.code == 200) {
          Message.success("取消收藏");
          this.startidear = false;
        } else {
          Message.error(res.data.message);
        }
      });
    },
    //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.searchpublic();
    },
    //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;
      this.searchpublic();
    },
  },
};
</script>
<style lang="scss" scoped>
.itemcenter {
  flex-grow: 1;
  padding: 0 10px;
}
.homeheader {
  border-bottom: 2px solid #e95157;
  .categorytitle {
    font-size: 16px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.56);
    .img {
      padding: 0 10px;
    }
  }
  .content {
    width: 60%;
    height: 99px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 740px;
    .left {
      box-sizing: border-box;
      width: 200px;
      // padding: 0px 13px 0px 14px;
      //  border: 1px solid red;
      img {
        width: 140px;
    
        vertical-align: middle;
        padding-left: 30px;
        // border: 1px solid red;
      }
    }
    .center {
      width: 700px;
      box-sizing: content-box;
      margin-left: 46px !important;
      height: 40px;
      border-radius: 3px;
      border: 2px solid #e94f55;
      display: flex;
      justify-content: start;
      flex-wrap: nowrap;
      .el-input {
        border: none;
      }

      .el-button--danger {
        border-radius: 0px;
        font-size: 16px;
        color: #e94f55ff;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
        .el-button:hover {
        border-radius: 0px;
        font-size: 16px;
        background-color: rgb(245,108,108) !important;
        border: 1px solid transparent;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .right1 {
      width: 138px;
      display: flex;
      padding-left: 29px;
      flex-wrap: wrap;
      // border: 1px solid red;

      .top {
        text-align: center;
        height: 22px;
        width: 120px;
        line-height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.56);
      }
      .bottom {
        color: #d0111aff;
        font-size: 18px;
        height: 25px;
        line-height: 25px;
        font-weight: 600;
        width: 100%;
      }
    }
  }
  .bottom {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: 43px;
    display: flex;
    justify-content: start;
    cursor: pointer;
    .categorytitle {
      width: 200px;
      height: 43px;
      background: #f8f8f8;
      // margin-left: 33px;
    }
    .tip {
      position: absolute;
      bottom: 37px;
      left: 14%;
      margin-left: 101px;
      width: 322px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
      line-height: 17px;
    }
  }
}
.home {
  min-width: 1100px;
}
.center {
  width: 100%;
  height: 680px;
  /* border: 1px solid red; */
}
.nav-wrap {
  // border: 1px solid red;
  // height: 500px;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  min-width: 800px;
}
.apps {
  height: 580px;
  // border: 1px solid red;
}
.reheader {
  display: flex;
  flex-direction: row;
  /* margin-left: 100px; */
  width: 50%;
  margin: 0 auto;
  /* border: 1px solid red; */
}
.rediv {
  display: flex;
  flex-direction: row;
  width: 100px;
  height: 29px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #868686;
  line-height: 27px;
  cursor: pointer;
  margin-left: 100px;
}
.reheader nav:nth-of-type(1) {
  border-right: 2px solid #979797;
  padding-right: 20px;
}

.active {
  color: #e94f55;
}
.commiddlee {
  //  border: 1px solid red;
  .itemexchange {
    display: flex;
    justify-content: space-between;
  }
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 10px;
  width: 100%;
  background: #f8f8f8;
  // border-radius: 10px;

  div {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
  .relediv {
    .el-button {
      margin-top: 30px;
    }
  }
}
.pagination {
  margin-top: 30px;
  // border: 1px solid red;
  // width: 100%;
  // margin: 0 auto;
  .el-pagination {
    width: 100%;
    margin: 0 auto;
  }
}

.relecontent {
  // width: 900px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  line-height: 40px;
  // border: 1px solid red;
}
.relebottomo {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
  line-height: 20px;
  // width: 900px;
  flex-wrap: wrap;
  // border: 1px solid red;

  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
.contente {
  // padding-left: -20px;
  box-sizing: border-box;
  // border: 1px solid red;
}
</style>
